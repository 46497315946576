
import { defineComponent, reactive, toRefs } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  emits: ['inputUrl'],
  setup(props, ctx) {
    const dataMap = reactive({
      myinput: props.value.length ? props.value : '',
      changeVal: (val: any) => {
        ctx.emit('inputUrl', val);
      }
    });

    return { ...toRefs(dataMap) };
  }

});
