
import { reactive, toRefs, defineComponent, computed } from 'vue';
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      }
    }

  },
  emits: ['formDropdown'],
  setup(props, ctx) {
    const dataMap = reactive({

      platformsOptions: [
        { key: 'a-platform', name: 'a-platform' },
        { key: 'b-platform', name: 'b-platform' },
        { key: 'c-platform', name: 'c-platform' }
      ],
      platforms: computed({
        get: () => {
          return props.value;
        },
        set: () => {
          ctx.emit('formDropdown', props.value);
        }
      }),
      change: (val: any) => {
        ctx.emit('formDropdown', val);
      }

    });

    return { ...toRefs(dataMap) };
  }
});
